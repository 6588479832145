@import "~bootstrap/scss/bootstrap";

@import "colors";
@import "fonts";
@import "icons";

@import "base";
@import "flex";
@import "lists";

@import "splash";
@import "table";

@import "controls/controls";

.border-red {
    border: solid 1px red;
}

#root_PolicyRoles__title {
    display: none;
}

#root_Drivers__title {
    display: none;
}

.array-item {
    width: 50%;
    border-style: solid;
    padding: 0em 1em 0em 0.1em;
    margin: 0em 0em .5em 0em;
}

.array-item-toolbox {
    display: none;
}

.btn-info{
    display: none;
}

span {
    word-break: inherit;
}