/* DO NOT EDIT!
 * This file is generated on build. Any and all changes will be overwritten.
 */

@function File ($_type) {
  @return map-get((
    'woff': 'homesite-icons.woff',
    'woff2': 'homesite-icons.woff2',
  ), $_type);
};

$Font: 'homesite-icons';

$Map: (
  'auto': '\ea01',
  'call': '\ea02',
  'chevron-down': '\ea03',
  'chevron-left': '\ea04',
  'chevron-right': '\ea05',
  'chevron-up': '\ea06',
  'contact-us': '\ea07',
  'facebook': '\ea08',
  'find': '\ea09',
  'home': '\ea0a',
  'linkedin': '\ea0b',
  'log-in': '\ea0c',
  'menu': '\ea0d',
  'quotes-left': '\ea0e',
  'quotes-right': '\ea0f',
  'renters': '\ea10',
  'search': '\ea11',
  'search-menu': '\ea12',
  'specialty': '\ea13',
  'twitter': '\ea14',
  'umbrella': '\ea15',
  'youtube': '\ea16',
);

@function Glyph ($_name) {
  @return map-get($Map, $_name);
}

@mixin Styles () {
  font: {
    family: 'homesite-icons';
    style: normal;
    variant: normal;
    weight: 400;
  }
  line-height: 1;
  text-rendering: auto;
  vertical-align: baseline;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}
