.navBar {
    background: "linear-gradient(45deg, #002855 15%, #287ba4 90%)";
}

.af-logobar {
    max-height: 105px;
    background: $af-BackgroundGrey;
  
    a {
      text-decoration: none;
      font-weight: bold;
    }
  
    span {
      word-break: normal !important;
    }
}
  
.af-logo {
  max-height: 75px;
}
  
.af-partner {
  max-height: 50px;
}
  
.af-phone-span {
  white-space: nowrap;
  display: none;
  @media screen and (min-width: 768px) {
    display: initial;
  }
}