form {
    label {
        font-weight: 600;
        color: $af-DarkBlue;
    }
}

.nav-form {
    display: flex;
    flex-direction: column;
    form {
        display: flex;
        flex-direction: column;
        border-spacing: 0.5rem;
        .button-box {
            width: 100%;
            vertical-align: bottom;
            button {
                margin-right: 2em;
                margin-left: 2em;
                margin-bottom: 1em;
            }
        }
        .form-group {
            flex: 2;
            display: flex;
            flex-direction: column;
            margin: 1em;
            fieldset {
                .form-group {
                    display: grid;
                    grid-template-columns: 8em auto;
                    input {
                        flex: 2;
                    }
                }
            }
        }
        .message-box {
            font-weight: 600;
            color: $af-AlertYellow;
            padding: 1rem 2rem;
        }
        border-bottom: solid 1px gray;
    }
    form:not(:last-child) {
        border-right: solid 1px gray;
    }
    label, input {
        display: table-cell;
    }
    .horiz-split {
        display: grid;
        grid-template-columns: auto auto;
        grid-template-rows: auto;
        max-width: 1200px;
    }
    @media screen and (max-width: 768px) {
        .horiz-split {
            grid-template-columns: auto;
            grid-template-rows: auto auto;
        }        
    }
    .result-container {
        padding: 1rem 0rem;
        text-align: center;
        .new-search-box {
            text-align: left;
            padding-bottom: 1rem;
        }
    }
}

.form-group {
    margin: 0;
}

.control-label {
    font-weight: 600;
    color: $af-DarkBlue;
}

.card-body {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

legend {
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
    font-size: 1.2em;
    color: $af-DarkBlue;   
}

.card {
    border: 0;
}

.card-header {
    border: 0;
    border-top: solid 1px $af-ShadowGrey;
    background-color: transparent;
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
    font-size: 1.2em;
    color: $af-MediumBlue;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}