/*
Basic HTML
*/
html body {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 1em;
  color: $af-LightGrey;
  background-color: $af-White;
}

a {
  color: $af-MediumBlue;
  &:hover, &:focus {
    color: $af-HoverBlue;
  }
}

.btn, button {
  font-weight: 700;
  font-family: "Oswald", sans-serif;
  text-transform: uppercase;
  text-align: center;
  border: 0;
  border-radius: 2px;
  background-color: $af-MediumBlue;
  letter-spacing: 0.05em;
  color: $af-White;
  width: auto;
  padding: 0.5em 0.75em;
  &:hover, &:focus {
    background-color: $af-FocusBlue;
    box-shadow: unset;
  }
  &:active {
    background-color: $af-ActiveBlue !important;
    box-shadow: none !important;
  }
}

.color-inherit {
  color: inherit !important;
}

.underline {
  text-decoration: underline;
}

/*
Headers
*/
h1 {
  font-family: "Oswald", sans-serif;
  font-weight: 600;
  font-size: 2.5em;
  color: $af-DarkBlue;
}

h2 {
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  font-size: 2.3em;
  color: $af-DarkBlue;
}

h3 {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  font-size: 1.8em;
  color: $af-DarkBlue;
}

h4 {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  font-size: 1.6em;
  color: $af-DarkBlue;
}

/*
Bootstrap adjustments
*/
select.form-control.is-invalid {
  background-position: right 1em center;
}

.invalid-feedback {
  padding-left: 0.25em;
}

/*
App
*/
.af-text-left {
  text-align: left;
}

.af-content {
  padding: 2em;
}

.af-banner {
  padding-left: 15px;
  padding-right: 15px;
  text-align: center;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 1em;
  background-color: $af-DarkBlue;
  color: $af-Cream;
}

.af-alert {
  padding-left: 15px;
  padding-right: 15px;
  text-align: center;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 1em;
  background-color: $af-AlertYellow;
  color: $af-DarkGrey;
}

.af-hero {
  font-family: "Oswald", sans-serif;
  font-weight: 600;
  font-size: 5.4em;
  color: $af-DarkGrey;
}

.af-btn-link {
  color: $af-MediumBlue;
  font-family: inherit;
  font-weight: inherit;
  font-size: inherit;
  margin: 0em;
  padding: 0em;
  padding-bottom: calc(0.2em + 1px) !important;
  line-height: inherit;
  &:hover, &:focus {
    color: $af-HoverBlue !important;
  }
  &:active {
    color: $af-ActiveBlue !important;
    background-color: transparent !important;
  }
}

.border-light-grey {
  border: 1px solid $af-LightGrey;
}

.nav {
  flex-grow: 1;
}