$af-DarkBlue: #002855;
$af-MediumBlue: #287ba4;
$af-FocusBlue: #2F91C1;
$af-ActiveBlue: #236C90;
$af-HoverBlue: #1e5c7b;
$af-DarkGrey: #25282a;
$af-LightGrey: #5b6770;
$af-ShadowGrey: #c9c9c9;
$af-BackgroundGrey: #fafafa;
$af-Cream: #fafafa;
$af-White: #fff;
$af-AlertYellow: #f7ce3e;
$af-ErrorRed: #a10025;
