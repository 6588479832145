.flex {
  display: flex;
}

.grow-wrap {
  flex-wrap: wrap;

  & > * {
    flex-grow: 1;
  }
}

.spaced-grow-wrap {
  @extend .grow-wrap;
  margin: -6px;

  & > * {
    margin: 6px;
  }

  & > .spaced-grow-wrap {
    margin: 0;
  }
}

.spaced-form-grow-wrap {
  @extend .grow-wrap;
  margin: -12px;
  align-items: flex-start;

  & > * {
    margin: 0;
  }

  & > .spaced-form-grow-wrap {
    margin: 0;
  }
}

.flex-list {
  position: relative;
  overflow: hidden;

  ul {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-left: -1px;
  }

  li {
    flex-grow: 1;
    flex-basis: auto;
    margin: 0.25em 0;
    padding: 0 0.5em;
    text-align: center;
    border-right: 1px solid;
    background-color: transparent;
  }

  li:last-of-type {
    border-right: none;
  }
}