.splash-content {
  padding: 1em;
}

.splash-info-head {
  background-color: #A0A0A0;
}

.splash-info-head h3 {
  color: #fff;
  font-weight: normal;
  font-size: 20px;
  padding: 10px;
  margin: 0px;
}

.splash-column {
  border-top: solid 1px #A0A0A0;
  border-bottom: solid 1px #A0A0A0;
  border-left: solid 1px #A0A0A0;
  &:last-of-type() {
    border-right: solid 1px #A0A0A0;
  }
  @media screen and (max-width: 768px) {
    border-right: solid 1px #A0A0A0;
  }
}
